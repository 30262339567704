import {useMutation} from '@tanstack/react-query';
import {useTranslation} from 'i18n';

import {UpdateImagesDataPayload, UploadImagePayload, UploadImagesGenerateUrlResponse} from '~redux/types/images';
import {imageDataActions, selectCurrentImage} from '~redux/reducers/imageReducer';
import {selectCurrentProjectId} from '~redux/reducers/userReducer';
import {fetchFilters} from '~redux/actions/filtersActions';
import {decreaseGlobalLoadingIndicator, increaseGlobalLoadingIndicator} from '~redux/actions/globalActions';
import {fetchUserData} from '~redux/actions/userActions';
import {useAppDispatch, useAppSelector} from '~redux/index';

import {showNotification} from '~components/common/Notification';
import {useLabelInstances} from 'src/contexts/LabelInstancesContext';
import {showApiErrorNotification} from './api';
import {
  deleteImages,
  generateImageUploadUrls,
  putImagesData,
  uploadImagesToBlobStorage,
  uploadImagesUrls,
} from './images';

/**
 * Update image
 */
export function useUpdateImagesMutation({skipCursor}: {skipCursor?: boolean} = {}) {
  const {t} = useTranslation(['review', 'common']);
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();
  const {activeViewMode, setIsPageDirty} = useLabelInstances();

  return useMutation((payload: UpdateImagesDataPayload) => putImagesData(projectId, payload, {skipCursor}), {
    onSuccess: (response) => {
      dispatch(imageDataActions.updateImageAnnotations(response));
      showNotification({severity: 'success', message: t('notificationAnnotationsSaved')});
      activeViewMode === 'instance' && setIsPageDirty(true);
    },
    onError: showApiErrorNotification,
  });
}

/**
 * Delete an image
 */
export function useDeleteImageMutation() {
  const dispatch = useAppDispatch();

  const projectID = useAppSelector(selectCurrentProjectId);
  const image = useAppSelector(selectCurrentImage);

  return useMutation(() => deleteImages(projectID, [image!.id]), {
    onMutate: () => {
      dispatch(increaseGlobalLoadingIndicator());
    },
    onSuccess: async () => {
      dispatch(imageDataActions.deleteImages([image!.id]));
      dispatch(decreaseGlobalLoadingIndicator());
    },
    onError: showApiErrorNotification,
  });
}

/**
 * Upload images
 */

async function readFileAsBuffer(file: File): Promise<Uint8Array> {
  const arrayBuffer = await file.arrayBuffer();
  return new Uint8Array(arrayBuffer);
}

export function useUploadImagesGenerateUrlsMutation() {
  const {t} = useTranslation(['review', 'common']);
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();

  return useMutation(
    async ({payload, files}: UploadImagesGenerateUrlResponse) => {
      const response = await generateImageUploadUrls(projectId, payload);
      return {response, files};
    },
    {
      onSuccess: async ({response, files}) => {
        const imageFiles = response.imageFiles;
        try {
          for (const [index, imageFile] of imageFiles.entries()) {
            const {urlPresigned} = imageFile;
            const file = files[index];
            const buffer = await readFileAsBuffer(file);

            await uploadImagesToBlobStorage(urlPresigned, buffer);
          }
          dispatch(imageDataActions.uploadImages(response));
        } catch (error) {
          showNotification({severity: 'error', message: t('imageUpload.error')});
        }
      },
      onError: showApiErrorNotification,
    },
  );
}

export function useUploadImagesMutation() {
  const projectId = useAppSelector(selectCurrentProjectId);
  const dispatch = useAppDispatch();

  return useMutation((payload: UploadImagePayload) => uploadImagesUrls(projectId, payload), {
    onSuccess: async () => {
      try {
        // User projects need to be refetched to reflect eventual productType or tags creation
        await dispatch(fetchUserData());
        // Same for perspective creation
        await dispatch(fetchFilters());
      } finally {
        dispatch(decreaseGlobalLoadingIndicator());
      }
    },
    onError: showApiErrorNotification,
  });
}
